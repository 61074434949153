import axios from 'axios'

function getBaseUrl() {
  if (window.location.hostname.match('client.dev.ashwellness.io') ||
    window.location.hostname === 'localhost') {
    return import.meta.env.CLIENT_PORTAL_DEV_API_BASE_URL
  }

  if (window.location.hostname.match('client.staging.ashwellness.io')) {
    return import.meta.env.CLIENT_PORTAL_STAGING_API_BASE_URL
  }

  return import.meta.env.CLIENT_PORTAL_PROD_API_BASE_URL
}

let baseURL = localStorage.getItem('location') || getBaseUrl()

if (!baseURL?.startsWith('https://pp-api.')) {
  baseURL = getBaseUrl()
}

export const axiosClient = axios.create({ baseURL })

axiosClient.defaults.headers.common['Access-Control-Allow-Origin'] = window.location.hostname
axiosClient.defaults.withCredentials = true
axiosClient.defaults.timeout = 8000

axiosClient.interceptors.request.use((request) => {
  const token = sessionStorage.getItem('token')

  if (token) {
    request.headers['x-access-token'] = token
  }

  request.headers['Content-Type'] = 'application/json'
  request.headers['accept'] = 'application/json'

  return request
})

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {

    if (
      error.response?.status === 401 &&
      error.response?.data?.message ===
      "You are not permitted to perform this action."
    ) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user_info");

      window.location.href = "/";

      return;
    }

    const customError = new Error();
    const defaultMessage = "An unexpected error occurred";

    customError.message = error.response?.data?.detail || defaultMessage;

    if (error.response?.status === 422) {
      console.log(error.response.data.detail)
      customError.message = !Array.isArray(error.response.data.detail) ? error.response.data.detail : error.response.data.detail[0].msg
    }

    customError.toString = function () {
      return this.message;
    };


    return Promise.reject(customError.toString());
  }
);